export function useImages () {

    function ratioResize (image, outputImageAspectRatio = 1) {
        const inputImage = new Image();

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        inputImage.addEventListener("load", async  () => {
            const inputWidth = inputImage.naturalWidth;
            const inputHeight = inputImage.naturalHeight;

            // get the aspect ratio of the input image
            const inputImageAspectRatio = inputWidth / inputHeight;

            // if it's bigger than our target aspect ratio
            let outputWidth = inputWidth;
            let outputHeight = inputHeight;
            if (inputImageAspectRatio > outputImageAspectRatio) {
                outputWidth = inputHeight * outputImageAspectRatio;
            } else if (inputImageAspectRatio < outputImageAspectRatio) {
                outputHeight = inputWidth / outputImageAspectRatio;
            }

            canvas.width = outputWidth;
            canvas.height = outputHeight;

            const x = 0 - (inputWidth/2) + (outputWidth/2)

            ctx.drawImage(inputImage, x, 0);

            image.value = await fetch(canvas.toDataURL()).then(r => r.blob()).then(blobFile => new File([blobFile], image.value.name, { type:image.value.type, lastModified: image.value.lastModified }))
        })
        inputImage.src = URL.createObjectURL(image.value)
    }

     /*const snapshotResize = async (srcData, aspectRatio) => {


    }*/


    return {
        ratioResize,
    }

}
