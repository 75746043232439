const Redirect = () => import(/* webpackChunkName: "core" */ '@/pages/Redirect.vue')
const Passport = () => import(/* webpackChunkName: "trainee-passport" */'@/pages/Passport.vue')
const PassportSkills = () => import(/* webpackChunkName: "trainee-passport" */'@/pages/passport/Skills.vue')
const PassportCV = () => import(/* webpackChunkName: "trainee-passport" */'@/pages/passport/CV.vue')
const TraineeEmployers = () => import(/* webpackChunkName: "trainee-employers" */'@/pages/Employers.vue')
const TraineeToken = () => import(/* webpackChunkName: "trainee" */ '@/pages/TraineeToken.vue')

export default [
    {
        path: '/passport',
        name: 'passport',
        component: Passport,
        redirect: (/*to*/) => {
            return {name: 'passport-index'}
        },
        meta: {
            guard: 'auth',
        },

        children: [
            {
                path: '',
                name: 'passport-index',
                component: Redirect,
                redirect: (/*to*/) => {
                    return {name: 'passport-skills'}
                },
                meta: {
                    title: 'Passeport B.E.E.',
                    guard: 'auth',
                },
            },
            {
                path: 'competences/:skill?',
                name: 'passport-skills',
                component: PassportSkills,
                meta: {
                    title: 'Compétences - Passeport B.E.E.',
                    guard: 'auth',
                },
            },
            {
                path: 'cv',
                name: 'passport-cv',
                component: PassportCV,
                meta: {
                    title: 'CV - Passeport B.E.E.',
                    guard: 'auth',
                },
            },
        ],

    },
    {
        path: '/employeurs',
        name: 'employers',
        component: TraineeEmployers,
        meta: {
            title: 'Employeurs',
            guard: 'auth',
        },
    },
    {
        path: '/access-token-print',
        name: 'access-token-print',
        component: TraineeToken,
        meta: {
            title: 'Passeport BEE',
            guard: 'auth',
            printable: 'true',
        },
    },
]
