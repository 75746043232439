<script setup>
import {computed, inject, markRaw} from "vue";

import {useRoute} from "vue-router";

import ConfirmDialog from "primevue/confirmdialog";
import DynamicDialog from 'primevue/dynamicdialog';
import Toast from "primevue/toast";

import HiveLoadingIndicator from '@/components/HiveLoadingIndicator.vue'
import UI from './UI.vue'
import UIPrintable from './UIPrintable.vue'

import {useAppInit} from "@/composables/useAppInit";

const route = useRoute()
const {initializing} = useAppInit()

const dynamicDialogDefaults = inject('dynamicDialogDefaults')

const $loading = computed(() => initializing.value)

const UIComponent = computed(() => {
    if (route.meta.printable) {
        return markRaw(UIPrintable)
    } else {
        return markRaw(UI)
    }
})
</script>

<template>
    <div v-if="$loading" class="text-center w-screen h-full flex justify-content-center">
        <HiveLoadingIndicator :size="120"/>
    </div>
    <component :is="UIComponent" :loading="$loading" v-else>
        <router-view></router-view>
    </component>
    <DynamicDialog />
    <ConfirmDialog :breakpoints="dynamicDialogDefaults.props.breakpoints" />
    <Toast group="ns"/>
    <Toast/>
</template>
