const Register = () => import(/* webpackChunkName: "register" */ '@/pages/auth/Register.vue')
const RegisterIndex = () => import(/* webpackChunkName: "register" */ '@/pages/auth/register/Index.vue')
const RegisterIndividual = () => import(/* webpackChunkName: "register" */ '@/pages/auth/register/Individual.vue')
const RegisterProfessional = () => import(/* webpackChunkName: "register" */ '@/pages/auth/register/Professional.vue')

export default [
    {
        path: '/enregistrement',
        name: 'register',
        component: Register,
        redirect: (/*to*/) => {
            return {name: 'register-index'}
        },
        meta: {
            title: "S'enregistrer",
            guard: 'guest-auth',
        },
        children: [
            {
                path: '',
                name: 'register-index',
                component: RegisterIndex,
                meta: {
                    title: 'S\'enregistrer',
                    guard: 'guest-auth',
                },
            },
            {
                path: 'stagiaire',
                name: 'register-individual',
                component: RegisterIndividual,
                meta: {
                    title: 'S\'enregistrer',
                    guard: 'guest-auth',
                },
            },
            {
                path: 'professionnel',
                name: 'register-professional',
                component: RegisterProfessional,
                meta: {
                    title: 'S\'enregistrer',
                    guard: 'guest-auth',
                },
            },
        ]
    },
]
