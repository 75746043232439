const Organizations = () => import(/* webpackChunkName: "organizations" */ '@/pages/Organizations.vue')
const Trainees = () => import(/* webpackChunkName: "trainees" */ '@/pages/Trainees.vue')
export default [
    {
        path: '/stagiaires',
        name: 'trainees',
        component: Trainees,
        meta: {
            title: 'Stagiaires',
            guard: 'auth',
        },
    },
    {
        path: '/organismes/:id(\\d+)?',
        name: 'organizations',
        component: Organizations,
        meta: {
            title: 'Organismes',
            guard: 'auth',
        },
    },
]
