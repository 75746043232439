import { createI18n } from 'vue-i18n'

import messages from './i18n/messages/index.js'

const i18n = createI18n( {
  locale: 'fr', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages
} )

export default i18n
