const Redirect = () => import(/* webpackChunkName: "core" */ '@/pages/Redirect.vue')
const Settings = () => import(/* webpackChunkName: "settings" */'@/pages/Settings.vue')
const SettingsSecurity = () => import(/* webpackChunkName: "settings-user" */'@/pages/settings/Security.vue')
const SettingsPrivacy = () => import(/* webpackChunkName: "settings-user" */'@/pages/settings/Privacy.vue')
const SettingsContent = () => import(/* webpackChunkName: "settings-content" */'@/pages/settings/Content.vue')
const SettingsApp = () => import(/* webpackChunkName: "settings-app" */ '@/pages/settings/App.vue')
const SettingsSkills = () => import(/* webpackChunkName: "settings-app" */ '@/pages/settings/Skills.vue')
const SettingsTools = () => import(/* webpackChunkName: "settings-tools" */ '@/pages/settings/Tools.vue')

export default [
    {
        path: '/reglages',
        name: 'settings',
        component: Settings,
        redirect: (/*to*/) => {
            return {name: 'settings-index'}
        },
        meta: {
            guard: 'auth',
        },

        children: [
            {
                path: '',
                name: 'settings-index',
                component: Redirect,
                redirect: (/*to*/) => {
                    return {name: 'settings-security'}
                },
                meta: {
                    title: 'Réglages',
                    guard: 'auth',
                },
            },
            {
                path: 'securite',
                name: 'settings-security',
                component: SettingsSecurity,
                meta: {
                    title: 'Réglages - Sécurité',
                    guard: 'auth',
                },
            },
            {
                path: 'confidentialite',
                name: 'settings-privacy',
                component: SettingsPrivacy,
                meta: {
                    title: 'Réglages - Confidentialité',
                    guard: 'auth',
                },
            },
            {
                path: 'contenus',
                name: 'settings-content',
                component: SettingsContent,
                meta: {
                    title: 'Réglages - Contenus',
                    guard: 'auth',
                },
            },
            {
                path: 'app',
                name: 'settings-app',
                component: SettingsApp,
                meta: {
                    title: 'Réglages - Application',
                    guard: 'auth',
                },
            },
            {
                path: 'skills',
                name: 'settings-skills',
                component: SettingsSkills,
                meta: {
                    title: 'Réglages - Compétences',
                    guard: 'auth',
                },
            },
            {
                path: 'outils',
                name: 'settings-tools',
                component: SettingsTools,
                meta: {
                    title: 'Réglages - Outils',
                    guard: 'auth',
                },
            },
        ],

    },
]
