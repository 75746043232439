const Users = () => import(/* webpackChunkName: "users" */ '@/pages/Users.vue')
const Trainee = () => import(/* webpackChunkName: "trainee" */ '@/pages/Trainee.vue')
const Redirect = () => import(/* webpackChunkName: "core" */ '@/pages/Redirect.vue')
const TraineeCV = () => import(/* webpackChunkName: "trainee-cv" */ '@/pages/trainee/CV.vue')
const TraineeSkills = () => import(/* webpackChunkName: "trainee-skills" */ '@/pages/trainee/Skills.vue')
const TraineeEmployers = () => import(/* webpackChunkName: "trainee-employers" */ '@/pages/trainee/Employers.vue')
export default [
    {
        path: '/utilisateurs/:id(\\d+)?',
        name: 'users',
        component: Users,
        meta: {
            title: 'Utilisateurs',
            guard: 'auth',
        },
    },
    {
        path: '/p/:token',
        name: 'traineeAccessTokenValidation',
        redirect: to => {
            return { name: 'trainees', query: { token: to.params.token } }
        },
        meta: {
            guard: 'auth',
        },
    },
    {
        path: '/stagiaire/:id(\\d+)',
        name: 'trainee',
        component: Trainee,
        redirect: (/*to*/) => {
            return {name: 'trainee-index'}
        },
        meta: {
            guard: 'auth',
        },

        children: [
            {
                path: '',
                name: 'trainee-index',
                component: Redirect,
                redirect: (/*to*/) => {
                    return {name: 'trainee-cv'}
                },
                meta: {
                    title: 'Stagiaire',
                    guard: 'auth',
                },
            },
            {
                path: 'cv',
                name: 'trainee-cv',
                component: TraineeCV,
                meta: {
                    title: 'CV - Stagiaire',
                    guard: 'auth',
                },
            },
            {
                path: 'competences/:skill?',
                name: 'trainee-skills',
                component: TraineeSkills,
                meta: {
                    title: 'Compétences - Stagiaire',
                    guard: 'auth',
                },
            },
            {
                path: 'employers',
                name: 'trainee-employers',
                component: TraineeEmployers,
                meta: {
                    title: 'Employeurs - Stagiaire',
                    guard: 'auth',
                },
            },
        ],

    },
]
