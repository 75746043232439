import * as validators from '@vuelidate/validators'
import i18n from "@/i18n"
import {helpers} from "@vuelidate/validators";
import {useOrganizations} from "@/stores/organizations";

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

// or import { createI18nMessage } from '@vuelidate/validators'
const {createI18nMessage} = validators
const { withAsync } = helpers
// extract the `t` helper, should work for both Vue 2 and Vue 3 versions of vue-i18n
const {t} = i18n.global || i18n

// pass `t` and create your i18n message instance
const withI18nMessage = createI18nMessage({t})

// wrap each validator.
export const email = withI18nMessage(validators.email)
export const url  = withI18nMessage(validators.url)
export const required = withI18nMessage(validators.required)
export const requiredIf = withI18nMessage(validators.requiredIf, {withArguments: true})
export const numeric = withI18nMessage(validators.numeric)

export const minValue = withI18nMessage(validators.minValue, {withArguments: true})
export const maxValue = withI18nMessage(validators.maxValue, {withArguments: true})

export const samePassword = withI18nMessage(validators.sameAs, {withArguments: true})
export const shouldAccept = withI18nMessage(validators.sameAs, {withArguments: true })

/**
 * Phone NC validator
 * @param param
 * @returns {ValidationRuleWithParams}
 */
const phoneNC$v = (value) => phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, 'NC'), 'NC')
export const phoneNC = withI18nMessage({
    $validator: phoneNC$v,
    $message: 'Invalid phone number',
    $params: {
        type: 'phoneNC'
    }
})

/**
 * Server side validation support
 * @param param
 * @returns {ValidationRuleWithParams}
 */
const serverSide$v = param =>
    helpers.withParams(
        {type: "serverSide", value: param},
        (/*value*/) => {
            if (!param.errors.value || undefined === param.errors.value[param.field]) return {$valid: true}

            return {
                $valid: false,
                message: param.errors.value[param.field][0]
            }
        }
    )

export const serverSide = param => helpers.withMessage(
    ({
         $response
     }) => {
        return $response ? $response.message : ''
    },
    serverSide$v(param)
)

const isNafValid$v = (value) => {

    const matches = value.match(/^([0-9]{4})([A-Za-z])$/)

    if( matches) {
        console.log(matches)
        return true
    }

    return false
}
export const isNafValid = withI18nMessage({
    $validator:isNafValid$v,
    $message: 'Invalid NAF code',
    $params: {
        type: 'isNafValid'
    }
})

const NAFexists$v = async (value, data) => {
    if(!isNafValid$v(value)) return false

    const response = await useOrganizations().queryNaf(value)

    if( !response || !response.total_count ) {
        data.naf_label = ''
        return false
    }

    data.naf_code = response.results[0].code_naf
    data.naf_label = response.results[0].intitule_naf

    return true
}
export const NAFexists = withAsync(withI18nMessage({
    $validator:NAFexists$v,
    $message: "This NAF code doesn't exists",
    $params: {
        type: 'NAFexists'
    }
},  ))
