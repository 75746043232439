import {defineStore, acceptHMRUpdate} from 'pinia'
import {computed, ref} from "vue";
import {useApi} from '@/stores/api'
import ky from "ky";
import {useMain} from "@/stores/main";
import {useOrganization} from "@/models/useOrganization";
import {useAuth} from "@/stores/auth";

export const useOrganizations = defineStore(`organizations`, () => {
    const mainStore = useMain()
    const apiStore = useApi()

    /**
     * States
     * @type {RemovableRef<string>}
     */
    const $records = ref({})

    function $reset() {
        $records.value = {}
    }

    /**
     * Getters
     */
    const records = computed(() => $records.value)
    const hasRecord = computed(() => $records.value.length > 0)

    const getById = async (id, forceFetch = false) => {
        if (!forceFetch && undefined !== $records.value[`${id}`]) {
            return $records.value[`${id}`]
        }

        if (undefined !== $records.value[`${id}`]) {
            await $records.value[`${id}`].fetch()
            if ($records.value[`${id}`].hasData) {
                return $records.value[`${id}`]
            } else {
                return false
            }
        }

        let organization = useOrganization({id})
        await organization.fetch()
        if (organization.hasData) {
            return $records.value[`${id}`] = organization
        }

        return false
    }

    const getByData = (data) => {
        if (undefined !== $records.value[`${data.id}`]) {
            $records.value[`${data.id}`].setData(data)
        } else {
            $records.value[`${data.id}`] = useOrganization(data)
        }

        return $records.value[`${data.id}`]
    }

    const fetch = async (options) => {
        options = {
            searchParams: {},
            ...options
        }

        try {
            const response = await apiStore.client().get('api/organizations',
                {searchParams: new URLSearchParams(options.searchParams)}
            ).json()

            // Build response
            response.data.forEach((data, index) => {
                $records.value[data.id] = getByData(data)
                response.data[index] = $records.value[data.id]
            })

            return response
        } catch (error) {
            await mainStore.catchCommonErrors(error)
            return false
        }

    }

    const destroy = async (organization) => {
        const organizationId = typeof organization === 'object' ? organization.id : organization
        try {
            const $organization = await getById(organizationId)
            if ($organization) {
                await $organization.destroy()
                if (undefined !== $records.value[`${organizationId}`]) {
                    delete ($records.value[`${organizationId}`])
                }
                return true
            }
        } catch (e) {
            return false
        }
        return false
    }

    const canAdd = computed(() => {
        const authStore = useAuth()
        return authStore.authUser.can('edit-others-organizations')
    })

    const queryRidet = async (q) => {

        if (!q) return false;
        const searchParams = {
            dataset: 'etablissements-actifs-au-ridet',
        }
        if (q) {
            const isRidet = q.match(/^([0-9]{7})\.([0-9]{3})$/)

            if (isRidet) {
                searchParams['refine.rid7'] = isRidet[1]
                searchParams['refine.ndegetablissement'] = isRidet[2]
            } else {
                searchParams.q = q
            }
        }

        return await ky.get('https://data.gouv.nc/api/records/1.0/search/', {
            searchParams
        }).json()
    }

    const queryNaf = async (q) => {

        if (!q) return false;
        const searchParams = {
            Limit: 20,
        }
        if (q) {
            const isNafCode = q.match(/^([0-9]{4})([A-z]{1})$/)

            if (isNafCode) {
                searchParams['refine'] = 'code_naf:' + q.toUpperCase()
                return await ky.get('https://data.gouv.nc/api/explore/v2.1/catalog/datasets/nomenclature-dactivites-francaise-naf-rev-2-code-ape/records', {
                    searchParams
                }).json()
            }
        }

        return null

    }

    return {
        records,
        hasRecord,
        fetch,
        destroy,
        canAdd,
        queryRidet,
        queryNaf,
        getById,
        getByData,
        $reset,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useOrganizations, import.meta.hot))
}
