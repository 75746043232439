const Login = () => import(/* webpackChunkName: "auth" */ '@/pages/auth/Login.vue')
const ForgotPassword = () => import(/* webpackChunkName: "auth" */ '@/pages/auth/ForgotPassword.vue')
const ResetPassword = () => import(/* webpackChunkName: "auth" */ '@/pages/auth/ResetPassword.vue')
const Verify = () => import(/* webpackChunkName: "auth" */ '@/pages/auth/Verify.vue')

export default [
    {
        path: '/connexion',
        name: 'login',
        component: Login,
        alias: '/login',
        query: {
            reset: 'reset',
            loggedout: 'loggedout',
            redirect_to: 'redirect_to',
            error: 'error',
            done: 'done',
        },
        meta: {
            title: 'Se connecter',
            guard: 'guest',
        },
    },
    {
        path: '/mot-de-passe-oublie',
        name: 'forgot-password',
        component: ForgotPassword,
        meta: {
            title: 'Mot de passe oublié',
            guard: 'guest',
        },
    },
    {
        path: '/reinitialisation-mot-de-passe/:token',
        name: 'password-reset',
        component: ResetPassword,
        query: {
            email: 'email',
        },
        meta: {
            title: 'Réinitialisation du mot de passe',
            guard: 'guest',
        },
    },
    {
        path: '/verification',
        name: 'account-verify',
        component: Verify,
        query: {
            resend: 'resend',
        },
        meta: {
            title: 'Vérification du compte',
            guard: 'auth',
        },
    },
]
