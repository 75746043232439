import {defineStore, acceptHMRUpdate} from 'pinia'
import {computed, ref} from "vue";
import {useApi} from '@/stores/api'
import {useMain} from "@/stores/main";
import {useUser} from "@/models/useUser";
import {useAuth} from "@/stores/auth";

export const useUsers = defineStore(`users`, () => {
    const mainStore = useMain()
    const apiStore = useApi()

    /**
     * States
     * @type {RemovableRef<string>}
     */
    const $records = ref({})

    function $reset() {
        $records.value = {}
    }

    /**
     * Getters
     */
    const records = computed(() => $records.value)
    const hasRecord = computed(() => $records.value.length > 0)

    const getById = async (id, forceFetch=false) => {
        if (!forceFetch && undefined !== $records.value[`${id}`]) {
            return $records.value[`${id}`]
        }

        if( undefined !== $records.value[`${id}`]) {
            await $records.value[`${id}`].fetch()
            if( $records.value[`${id}`].hasData ) {
                return $records.value[`${id}`]
            } else {
                return false
            }
        }

        let user = useUser({id})
        await user.fetch()
        if (user.hasData) {
            $records.value[`${id}`] = user
            return $records.value[`${id}`]
        }

        return false
    }

    const getByData = (data) => {
        if( undefined !== $records.value[`${data.id}`]) {
            $records.value[`${data.id}`].setData(data)
        }
        else {
            $records.value[`${data.id}`] = useUser(data)
        }

        return $records.value[`${data.id}`]
    }

    const fetch = async (options) => {
        options = {
            searchParams: {},
            ...options
        }

        try {
            const response = await apiStore.client().get('api/users',
                {searchParams: new URLSearchParams(options.searchParams)}
            ).json()

            // Build response
            response.data.forEach((data, index) => {
                $records.value[data.id] = useUser(data)
                response.data[index] = $records.value[data.id]
            })

            return response
        } catch (error) {
            await mainStore.catchCommonErrors(error)
            return false
        }

    }

    const destroy = async (user) => {
        const userId = typeof user === 'object' ? user.id : user
        try {
            const $user = await getById(userId)
            if ($user) {
                await $user.destroy()
                if (undefined !== $records.value[`${userId}`]) {
                    delete ($records.value[`${userId}`])
                }
                return true
            }
        } catch (e) {
            return false
        }
        return false

    }

    const canAdd = computed(() => {
        const authStore = useAuth()
        return authStore.authUser.can('edit-others-users')
    })

    return {
        records,
        hasRecord,
        fetch,
        getById,
        getByData,
        destroy,
        canAdd,

        $reset,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useUsers, import.meta.hot))
}
