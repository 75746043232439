import {useUser} from "@/models/useUser";
import {ref, computed} from "vue";
import {useAuth} from "@/stores/auth";
import {useApi} from "@/stores/api";
import {useMain} from "@/stores/main";
import {useOrganizations} from "@/stores/organizations";

export function useTrainee(data = {}) {

    //data = JSON.parse(JSON.stringify(isRef(data) ? data.value : data))

    const $data = ref(data)
    const type = 'trainee'

    const formFields = {
        firstname: '',
        lastname: '',
        birthdate: '',
        email: '',
        phone: '',
        city: '',
        area: '',
        avatar: null,
    }

    const {
        hasData,
        formData,
        formDataObject,
        id,
        updatedAt,
        createdAt,
        $$,
        fetch,
        setData,
        save,
        saveForm,
        update,
        destroy,
        roles,
        permissions,
        isActive,
        isEmailVerified,
        isPrivacyAccepted,
        isTosAccepted,
        isTrainee,
        isSubscribed,
        meta,
        updateMeta,
        avatarUrl,
        hasRole,
        can,
        canNavigate,
    } = useUser($data, type, formFields)

    const canEdit = computed(() => {
        const authStore = useAuth()

        if (authStore.authUser.hasRole('trainee')) {
            return false
        }

        // edit self organization
        if (authStore.authUser.hasTrainee(id.value)) {
            return authStore.authUser.can('edit-trainees')
        }
        return authStore.authUser.can('edit-others-trainees')
    })

    const canView = computed(() => {
        const authStore = useAuth()

        if (authStore.authUser.hasRole('trainee')) {
            return false
        }

        // edit self organization
        if (authStore.authUser.hasTrainee(id.value)) {
            return authStore.authUser.can('view-trainees')
        }

        return authStore.authUser.can('view-others-trainees')
    })

    const canDestroy = computed(() => {
        const authStore = useAuth()

        if (authStore.authUser.hasRole('trainee')) {
            return false
        }

        // edit self organization
        if (authStore.authUser.hasTrainee(id.value)) {
            return authStore.authUser.can('delete-trainees')
        }
        return authStore.authUser.can('delete-others-trainees')
    })

    const canRemove = computed(() => {
        const authStore = useAuth()
        if (authStore.authUser.hasRole('trainee')) {
            return false
        }

        // edit self organization
        if (authStore.authUser.hasTrainee(id.value)) {
            return authStore.authUser.can('remove-organization-trainees')
        }
        return authStore.authUser.can('remove-others-organization-trainees')
    })

    const canEditTraineesDrivingLicences = computed(() => {
        const authStore = useAuth()

        if (authStore.authUser.hasRole('trainee')) {
            return false
        }

        // edit self organization
        if (authStore.authUser.hasTrainee(id.value)) {
            return authStore.authUser.can('edit-trainees-driving-licences')
        }
        return authStore.authUser.can('edit-others-trainees-driving-licences')
    })
    const canValidateIndicator = computed(() => {
        const authStore = useAuth()

        if (authStore.authUser.hasRole('trainee')) {
            return false
        }

        // edit self organization
        if (authStore.authUser.hasTrainee(id.value)) {
            return authStore.authUser.can('validate-trainees-skills')
        }
        return authStore.authUser.can('validate-others-trainees-skills')
    })

    const canInvalidateIndicators = computed(() => {
        const authStore = useAuth()

        if (authStore.authUser.hasRole('trainee')) {
            return false
        }

        // edit self organization
        if (authStore.authUser.hasTrainee(id.value)) {
            return authStore.authUser.can('invalidate-trainees-skills')
        }
        return authStore.authUser.can('invalidate-others-trainees-skills')
    })

    const canInvalidateIndicator = (indicator, isOrganizationValidation = true) => {

        const authStore = useAuth()

        if (!canInvalidateIndicators.value) {
            return false
        }

        if (!isOrganizationValidation) {
            if (!(authStore.authUser.isSuperAdmin || authStore.authUser.isAdmin)) {
                return false
            }
            return true
        }

        if (authStore.authUser.isSuperAdmin || authStore.authUser.isAdmin) {
            return true
        }

        if (indicator.validation.organization_id === authStore.authUser.organization.id) {
            return true
        }

        return authStore.authUser.can('validate-others-trainees-skills')
    }

    const qualifications = computed(() => $data.value.qualifications)
    const jobs = computed(() => $data.value.jobs)

    const saveLicenses = async (licenses) => {
        const apiStore = useApi()

        try {
            let response
            if ($data.value.id) {
                response = await apiStore.client()
                    .put(`api/${type}s/${id.value}/driving-licenses`, {json: {data: {licenses}}}).json()
            }
            return response.data
        } catch (error) {
            return false
        }
    }

    const removeOrganization = async (organization) => {
        const apiStore = useApi()

        try {
            await apiStore.client().delete(`api/user/organization/${organization.id}`).json()
            $data.value.organizations.forEach((data, index) => {
                if (data.id === organization.id) {
                    $data.value.organizations.splice(index, 1)
                }
            })
            return true
        } catch (error) {
            return false
        }
    }

    const skill = (skillId) => {
        return skills.value.find(o => o.id === skillId)
    }

    const employers = computed(() => {
        const data = []
        if ($data.value.organizations) {
            for (const value of $data.value.organizations) {
                data.push(useOrganizations().getByData(value))
            }
        }
        return data
    })

    const skills = computed(() => {

        if (!hasData) {
            console.log('no data, no skills')
            return
        }

        const skills = JSON.parse(JSON.stringify(useMain().skills))

        for (let $skill of skills) {
            let validatedIndicator4Skill = 0
            let numIndicator4Skill = 0

            for (let $criteria of $skill.children) {
                let validatedIndicator4Criteria = 0
                for (let $indicator of $criteria.children) {
                    numIndicator4Skill++
                    $indicator.validation = false

                    let validation = $data.value.validations ? $data.value.validations.find(o => o.skill_id === $indicator.id) : false
                    if (validation) {
                        let fullyValidated = !!validation.organization_validated_at && !!validation.admin_validated_at
                        if (fullyValidated) {
                            validatedIndicator4Skill++
                            validatedIndicator4Criteria++
                        }

                        $indicator.validation = validation
                        $indicator.validated = fullyValidated
                    }
                }
                $criteria.progress = Math.round(validatedIndicator4Criteria * 100 / $criteria.children.length)
            }
            $skill.progress = Math.round(validatedIndicator4Skill * 100 / numIndicator4Skill)
        }
        return skills
    })

    const status = computed(() => {
        if (!$data.value.email_verified_at) {
            return 3
        } else if (!!$data.value.firstname
            && !!$data.value.lastname
            && !!meta('birthdate')
            && !!$data.value.email
            && !!meta('city')
        ) {
            if ($data.value.activated_at) {
                return 1
            } else {
                return 0
            }
        } else {
            return 2
        }
    })
    const validateIndicator = async (indicator) => {
        if (!$data.value.id) {
            return null;
        }

        const apiStore = useApi()

        let response
        try {
            response = await apiStore.client()
                .put(`api/${type}s/${id.value}/validate-indicator/${indicator.id}`, {json: {}}).json()
        } catch (error) {
            return false
        }

        if (!response.data) {
            return false;
        }
        const existing = $data.value.validations.findIndex(o => o.id === response.data.id)
        if (existing !== -1) {
            $data.value.validations[existing] = response.data
        } else {
            $data.value.validations.push(response.data)
        }

        return true

    }

    const invalidateIndicator = async (indicator, isOrganizationValidation = true) => {
        if (!$data.value.id) {
            return null;
        }

        const apiStore = useApi()

        let response
        try {
            response = await apiStore.client()
                .delete(`api/${type}s/${id.value}/validate-indicator/${indicator.id}`, {searchParams: {isOrganizationValidation}}).json()
        } catch (error) {
            return false
        }

        if (!response.data) {
            return false;
        }

        const existing = $data.value.validations.findIndex(o => o.skill_id === indicator.id)
        if (existing !== -1) {
            if( response.data === true) {
                $data.value.validations.splice(existing, 1)
            } else {
                $data.value.validations[existing] = response.data
            }
        }
        return true

    }

    const newAccessToken = async (type) => {
        const formData = new FormData
        formData.append('type', type || 'short')

        const response = await useApi().client().post(`api/trainee/access-tokens`, {body: formData}).json()
        return response.data
    }

    const updateJob = async (data, processing, setErrors) => {
        let response
        try {
            if (data.id) {
                response = await useApi().client()
                    .put(`api/${type}s/${id.value}/job/${data.id}`, {json: data}).json()
            } else {
                response = await useApi().client()
                    .post(`api/${type}s/${id.value}/job`, {json: data}).json()
            }

        } catch (error) {
            return useApi().processFormErrors(error, setErrors, processing)
        }

        if (!response.data) {
            return false
        }

        return response.data
    }
    const deleteJob = async (job) => {
        try {
            await useApi().client()
                .delete(`api/${type}s/${id.value}/job/${job.id}`, {json: data}).json()

        } catch (error) {
            return new Promise((resolve/*, reject*/) => {
                resolve(false)
            })
        }

        return new Promise((resolve/*, reject*/) => {
            resolve(true)
        })
    }
    const updateQualification = async (data, processing, setErrors) => {
        let response
        try {
            if (data.id) {
                response = await useApi().client()
                    .put(`api/${type}s/${id.value}/qualification/${data.id}`, {json: data}).json()
            } else {
                response = await useApi().client()
                    .post(`api/${type}s/${id.value}/qualification`, {json: data}).json()
            }
        } catch (error) {
            return useApi().processFormErrors(error, setErrors, processing)
        }

        return response
    }
    const deleteQualification = async (qualification) => {
        try {
            await useApi().client()
                .delete(`api/${type}s/${id.value}/qualification/${qualification.id}`, {json: data}).json()

        } catch (error) {
            return false
        }

        return true
    }

    return {
        $data,
        hasData,
        formData,
        formDataObject,
        fetch,
        id,
        updatedAt,
        createdAt,
        $$,
        setData,
        save,
        saveForm,
        update,
        destroy,

        saveLicenses,
        removeOrganization,
        validateIndicator,
        invalidateIndicator,

        isActive,
        isEmailVerified,
        isPrivacyAccepted,
        isTosAccepted,
        isTrainee,
        isSubscribed,
        roles,
        permissions,
        meta,
        updateMeta,
        avatarUrl,
        hasRole,
        can,
        canNavigate,
        canEdit,
        canView,
        canDestroy,
        canRemove,
        canEditTraineesDrivingLicences,
        canValidateIndicator,
        canInvalidateIndicator,
        qualifications,
        jobs,
        skill,
        skills,
        status,
        employers,
        newAccessToken,
        updateJob,
        deleteJob,
        updateQualification,
        deleteQualification,
    }
}
