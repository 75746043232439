const Profile = () => import(/* webpackChunkName: "profile" */ '@/pages/Profile.vue')
const Settings = () => import(/* webpackChunkName: "settings" */ '@/pages/Settings.vue')
const Download = () => import(/* webpackChunkName: "settings" */ '@/pages/Download.vue')

export default [
    {
        path: '/profil',
        name: 'profile',
        component: Profile,
        meta: {
            title: 'Profil',
            guard: 'auth',
        },
    },
    {
        path: '/reglages',
        name: 'settings',
        component: Settings,
        meta: {
            title: 'Settings',
            guard: 'auth',
        },
    },
    {
        path: '/download/:type/:filename',
        name: 'download',
        component: Download,
        meta: {
            title: 'Téléchargement',
            guard: 'auth',
        },
    },
]
