import {createWebHistory, createRouter} from 'vue-router'
import {useAuth} from '@/stores/auth'
import {useMain} from "@/stores/main";

import PublicRoutes from './public'
import AuthRoutes from './auth'
import RegisterRoutes from './register'
import CommonRoutes from './common'
import AdminRoutes from './admin'
import OrganizationsRoutes from './organizations'
import TraineesRoutes from './trainees'
import SettingsRoutes from './settings'


const PageNotFound = () => import(/* webpackChunkName: "core" */ '@/pages/errors/404.vue')
const Dashboard = () => import(/* webpackChunkName: "core" */ '@/pages/Dashboard.vue')

// const Trainees = () => import(/* webpackChunkName: "trainees" */ '@/pages/Trainees.vue')

const APP_NAME = process.env.VUE_APP_NAME

const routes = [
    {
        path: '/',
        component: Dashboard,
        name: 'home',
        query: {
            verified: 'verified',
        },
        meta: {
            guard: 'auth',
        },
    },

    ...PublicRoutes,
    ...RegisterRoutes,
    ...AuthRoutes,
    ...CommonRoutes,
    ...AdminRoutes,
    ...OrganizationsRoutes,
    ...TraineesRoutes,
    ...SettingsRoutes,

    /*{
        path: '/stagiaires/:token',
        name: 'trainees-token',
        component: Trainees,
        meta: {
            title: 'Stagiaires',
            guard: 'auth',
        },
    },*/

    {path: '/:pathMatch(.*)*', name: 'NotFound', component: PageNotFound},
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

// Navigation guard
router.beforeEach((to, from, next) => {
    const store = useAuth()

    const auth = store.authenticated
    const verified = store.hasVerified
    const activated = store.isActive
    const isSubscribed = store.isSubscribed

    if (to.matched.some(route => route.meta.guard === 'guest') && auth) {
        if (to.query.redirect_to) {
            window.location.href = atob(to.query.redirect_to)
            next()
        } else {

            if (isSubscribed) {
                next({name: 'home'})
            } else {
                next({name: 'register'})
            }
        }
    }
    if (to.matched.some(route => route.meta.guard === 'guest-auth') && auth && isSubscribed) {
        if (to.query.redirect_to) {
            window.location.href = atob(to.query.redirect_to)
            next()
        } else {
            next({name: 'home'})
        }
    } else if (to.matched.some(route => route.meta.guard === 'auth') && !auth) {
        const redirect = {name: 'login'}
        if (to.name !== 'home') {
            redirect.query = {redirect_to: btoa(to.fullPath)}
        }
        next(redirect)
    } else if (to.matched.some(route => route.meta.guard === 'auth') && auth && !isSubscribed) {
        const redirect = {name: 'login'}
        if (to.name !== 'home') {
            redirect.query = {redirect_to: btoa(to.fullPath)}
        }
        next(redirect)
    } else if (to.name !== 'account-verify' /*&& to.name !== 'profile' && to.name !== 'settings'*/ && isSubscribed
        && to.matched.some(route => route.meta.guard === 'auth')
        && (!verified || !activated)) {
        next({name: 'account-verify'})
    } else {
        next()
    }
})

router.beforeEach((to, from, next) => {
    const mainStore = useMain()
    mainStore.routeLoading = true
    next()
})
router.afterEach(() => {
    const mainStore = useMain()
    mainStore.routeLoading = false
    setTimeout(() => mainStore.routeLoading = false, 1500)
})


// Page Title and Metadata
router.beforeEach((to, from, next) => {
    const nearestWithTitle = to.matched
        .slice()
        .reverse()
        .find(r => r.meta && r.meta.title)

    const nearestWithMeta = to.matched
        .slice()
        .reverse()
        .find(r => r.meta && r.meta.metaTags)

    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title + ' - ' + APP_NAME
    } else {
        document.title = APP_NAME
    }

    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(
        el => el.parentNode.removeChild(el),
    )

    if (!nearestWithMeta) return next()

    nearestWithMeta.meta.metaTags
        .map(tagDef => {
            const tag = document.createElement('meta')

            Object.keys(tagDef).forEach(key => {
                tag.setAttribute(key, tagDef[key])
            })

            tag.setAttribute('data-vue-router-controlled', '')

            return tag
        })

        .forEach(tag => document.head.appendChild(tag))

    next()
})

export default router
