import {computed} from "vue";
import {useApi} from "@/stores/api";
import {useDate} from "@/composables/useDate";

export function useBase(type, $data, formFields, init) {

    const hasData = computed(() => ($data && $data.value && Object.keys($data.value).length > 1))

    const formData = computed(() => __buildFormData($data.value, true))

    const formDataObject = computed(() => __parseFormData($data.value, true))

    const id = computed(() => {
        return $data.value ? $data.value.id : null
    })

    const updatedAt = computed(() => {
        const date = useDate()
        return $data.value ? date($data.value.updated_at) : null
    })

    const createdAt = computed(() => {
        const date = useDate()
        return $data.value ? date($data.value.created_at) : null
    })

    const $$ = (index) => {
        return $data.value ? ($data.value[index] || null) : null
    }

    const setData = (data) => {
        if ($$('id') && (data.id || null) && parseInt($$('id')) !== (data.id || null)) {
            console.error('Invalid ID', $$('id'), data.id, typeof $$('id'), typeof data.id)
            throw new Error('Invalid model ID when setting data.')
        }
        $data.value = data
        if(init) {
            init()
        }
    }

    const fetch = async () => {
        const apiStore = useApi()
        const record = await apiStore.client().get(`api/${type}s/${id.value}`).json()
        setData(record.data)
    }

    const save = async () => {
        const apiStore = useApi()
        return apiStore.client().post(`api/${type}s/${id.value}`, {data: formData.value});
    }

    const update = async (data, processing, setErrors) => {
        const apiStore = useApi()
        let response

        processing.value = true
        try {
            response = await apiStore.client()
                    .post(`api/${type}s/${id.value}?_method=PUT`, {json: data}).json()
        } catch (error) {
            return apiStore.processFormErrors(error, setErrors, processing)
        }
        setData(response.data)
        processing.value = false
        return response
    }
    const saveForm = async (form, processing, setErrors) => {
        const apiStore = useApi()

        form = __parseFormData(form, false)

        processing.value = true

        try {
            let response
            if ($data.value.id) {
                response = await apiStore.client()
                    .post(`api/${type}s/${id.value}?_method=PUT`, {body: __buildFormData(form, false)}).json()
            } else {
                response = await apiStore.client()
                    .post(`api/${type}s`, {body: __buildFormData(form, false)}).json()
            }
            processing.value = false

            setData(response.data)


            return response
        } catch (error) {
            return apiStore.processFormErrors(error, setErrors, processing)
        }
    }

    const destroy = async () => {
        const apiStore = useApi()
        return apiStore.client().delete(`api/${type}s/${id.value}`);
    }

    const __buildFormData = (data, defaults = false) => {
        data = __parseFormData(data, defaults)

        const formData = new FormData
        Object.keys(data).forEach((key) => {
            let _data = data[key]
            if (typeof _data === 'object' && !(data[key] instanceof File)) {
                _data = JSON.stringify(_data)
            }
            formData.append(key, _data)
        })

        return formData
    }

    const __parseFormData = (data, defaults = false) => {
        const formDataObject = {}
        Object.keys(formFields).forEach((key) => {
            if (defaults) {
                const $value = data[key] || formFields[key]
                formDataObject[key] = $value
            } else if (undefined !== data[key]) {
                formDataObject[key] = data[key]
            }
        })
        return formDataObject
    }

    return {
        id,
        updatedAt,
        createdAt,
        $$,
        setData,
        hasData,
        formData,
        formDataObject,
        fetch,
        save,
        saveForm,
        update,
        destroy,
    }
}
