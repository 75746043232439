import {fr} from './fr/index.js'

const messages = {
    en: {
        hello: 'Hello, world!'
    },
    fr,
}

export default messages
