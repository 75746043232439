import {useBase} from "@/models/useBase";
import {ref, inject, computed, isRef} from "vue";
import {useApi} from "@/stores/api";
import {useAuth} from "@/stores/auth";

import OrganizationView from '@/components/Organization/View.vue'
import OrganizationEdit from '@/components/Organization/Edit.vue'

import {useDialog} from 'primevue/usedialog';
import {useUsers} from "@/stores/users";



export function useOrganization(data = {}) {
    const dynamicDialogDefaults = inject('dynamicDialogDefaults')

    data = JSON.parse(JSON.stringify(isRef(data) ? data.value : data))

    const $data = ref(data)
    const type = 'organization'

    const formFields = {
        display_name: '',
        ridet_raw: '',
        phone: '',
        email: '',
        address: '',
        address2: '',
        city: '',
        certified: 0,
        logo: null
    }

    const {
        hasData,
        formData,
        formDataObject,
        id,
        updatedAt,
        createdAt,
        $$,
        setData,
        fetch,
        save,
        saveForm,
        destroy
    } = useBase(type, $data, formFields)

    const isCertified = computed(() => !!$data.value.certified)

    const users = computed(() => {
        const data = []
        if ($data.value.users) {
            for (const value of $data.value.users) {
                let u = useUsers().getByData(value)
                data.push(u)
            }
        }
        return data
    })

    const logoUrl = computed(() => {
        const apiStore = useApi()

        if ($data.value.logo) {
            const hash = window.btoa($data.value.logo || 'default')
            return apiStore.url(`api/organizations/${$data.value.id}/logo/${hash}`)
        }

        return '/img/avatar-organization.png'
    })

    const canEdit = computed(() => {
        const authStore = useAuth()

        if (authStore.authUser.hasRole('trainee')) {
            return false
        }

        // edit self organization
        if (authStore.authUser.organization.id === $data.value.id) {
            return authStore.authUser.can('edit-organization')
        }
        return authStore.authUser.can('edit-others-organizations')
    })

    const canView = computed(() => {
        const authStore = useAuth()

        if (authStore.authUser.hasRole('trainee')) {
            return false
        }

        // edit self organization
        if (authStore.authUser.organization.id === $data.value.id) {
            return authStore.authUser.can('view-organization')
        }

        return authStore.authUser.can('view-others-organizations')
    })

    const canDestroy = computed(() => {
        const authStore = useAuth()

        if (authStore.authUser.hasRole('trainee')) {
            return false
        }

        if (authStore.authUser.organization.id === $data.value.id) { // forbid delete self org
            return false
        }

        // delete self organization
        if (authStore.authUser.organization.id === $data.value.id) {
            return authStore.authUser.can('delete-organization')
        }
        return authStore.authUser.can('delete-others-organizations')
    })

    const isProfileComplete = computed(()=>{

        const wanting = ['display_name', 'phone', 'email', 'address'];

        for(const value of wanting) {
            if( ! $data.value[value]) return false
        }

        return true
    })

    const dialogView = (item) => {

        const dialog = useDialog();
        return dialog.open(OrganizationView, {
            ...dynamicDialogDefaults,
            props: {
                ...dynamicDialogDefaults.props,
                header: 'Organisation'
            },
            data: {
                item,
            }
        });
    }

    const dialogEdit = (item) => {

        const dialog = useDialog();
        return dialog.open(OrganizationEdit, {
            ...dynamicDialogDefaults,
            props: {
                ...dynamicDialogDefaults.props,
                header: "Modifier un organisme"
            },
            data: {
                item,
            }
        });
    }

    const removeTrainee = async (trainee) => {

        const apiStore = useApi()

        try {
            await apiStore.client().delete(`api/organizations/${id.value}/trainee/${trainee.id}`).json()
        } catch (error) {
            return error
        }

        if($data.value.trainees) {
            $data.value.trainees.forEach((data, index) => {
                if (data.id === trainee.id) {
                    $data.value.trainees.splice(index, 1)
                }
            })
        }
        if(trainee.$data.organizations) {
            trainee.$data.organizations.forEach((data, index) => {
                if (data.id === id.value) {
                    trainee.$data.organizations.splice(index, 1)
                }
            })
        }

        return true
    }

    return {
        $data,
        hasData,
        formData,
        formDataObject,
        fetch,
        id,
        updatedAt,
        createdAt,
        $$,
        setData,
        save,
        saveForm,
        destroy,
        removeTrainee,

        isCertified,
        isProfileComplete,

        logoUrl,
        canEdit,
        canView,
        canDestroy,

        dialogView,
        dialogEdit,

        users,
    }
}
