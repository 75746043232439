import {acceptHMRUpdate, defineStore} from 'pinia'
import ky from "ky";
import {computed, ref} from "vue";
import {useMain} from "@/stores/main";
import {useCookies} from "vue3-cookies";

export const useApi = defineStore('api', () => {

    const mainStore = useMain()
    const {cookies} = useCookies()

    const csrfLastUpdate = ref()

    const prefixUrl = ref(process.env.VUE_APP_PUBLIC_BACKEND_URL)
    const useNcConnect = ref(process.env.VUE_APP_USE_NC_CONNNECT)

    const ncConnectRedirectUrl = computed(() => {
        return prefixUrl.value + '/auth/ncconnect/redirect';
    })

    const $reset = () => {
        csrfLastUpdate.value = null
        prefixUrl.value = process.env.VUE_APP_PUBLIC_BACKEND_URL
    }

    const httpClient = ky.create({
        prefixUrl: prefixUrl.value,
        cache: 'no-store',
        credentials: 'include',
        retry: 0,
        timeout: process.env.VUE_APP_HTTPR_TIMEOUT || 10000,
        hooks: {
            beforeRequest: [
                request => {
                    request.headers.set('X-Requested-With', 'XMLHttpRequest')
                },
                async request => {
                    if (
                        request.method == 'POST' ||
                        request.method == 'PUT' ||
                        request.method == 'DELETE'
                    ) {

                        //if(csrfLastUpdate.value) console.log(Math.floor((Math.abs(Date.now() - csrfLastUpdate.value) / 1000) / 60))
                        if (!csrfToken() || !csrfLastUpdate.value || Math.floor((Math.abs(Date.now() - csrfLastUpdate.value) / 1000) / 60) > 15) {
                            await csrf()
                        }
                        if (csrfToken()) {
                            request.headers.set('X-XSRF-TOKEN', csrfToken())
                        } else {
                            throw new Error('No CSRF token.')
                        }
                    }
                },
                request => {
                    const toReplace = prefixUrl.value + '/' + prefixUrl.value
                    if(request.url.startsWith(toReplace)) {
                        request.url = request.url.replace(toReplace, prefixUrl.value)
                    }
                },
            ],
        }
    })

    const csrfToken = () => {
        const token = cookies.get('XSRF-TOKEN')
        if (token) {
            if(!csrfLastUpdate.value) {
                csrfLastUpdate.value = Date.now()
            }
            return decodeURIComponent(token)
        }
        return false
    }
    const client = () => httpClient

    const csrf = async () => {
        try {
            const response = await client().get('sanctum/csrf-cookie').json()
            csrfLastUpdate.value = Date.now()
            return response
        } catch (error) {
            csrfLastUpdate.value = false
            await mainStore.catchCommonErrors(error)
            return false
        }
    }

    const processFormErrors = async (error, setErrors, processing) => {
        let errors = await mainStore.catchCommonErrors(error)

        processing.value = false

        if (errors.status === 422) {
            errors = await error.response.json()
        }

        if (errors.errors && Object.keys(errors.errors).length) {
            setErrors.value = errors
        }

        return false
    }

    const url = (path='') => {
        const $url = prefixUrl.value.endsWith('/') ? prefixUrl.value.slice(0, -1) : prefixUrl.value;
        if(path) {
            return $url + (path.startsWith('/') ? '' : '/') + path
        }
        return $url
    }

    return {$reset, client, csrf, csrfToken, processFormErrors, prefixUrl, useNcConnect, ncConnectRedirectUrl, url}
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useApi, import.meta.hot))
}
