import {acceptHMRUpdate, defineStore, getActivePinia} from 'pinia'
import {computed, ref} from "vue";
import {HTTPError} from "ky";
import {useApi} from "@/stores/api";
import {useAuth} from "@/stores/auth";
import {useDictionaries} from "@/stores/dictionaries";
import {useStorage} from "@vueuse/core";
import {useRouter} from "vue-router";

export const useMain = defineStore('main', () => {
    const mainStore = useMain()
    const authStore = useAuth()
    const apiStore = useApi()
    const router = useRouter()

    /**
     * Hold the connectivity status
     * @type {Ref<UnwrapRef<string>>}
     */
    const connectivityStatus = ref('ok')
    const routeLoading = ref(true)

    /**
     * True if connectivity is ok
     * @type {ComputedRef<boolean>}
     */
    const isNetworkConnected = computed(() => {
        if (!navigator.onLine) {
            return false;
        }
        return 'ok' === connectivityStatus.value
    })

    const interval = ref()

    const skills = useStorage('traineeSkills', [])
    const app = useStorage('appSettings', {})

    const completeOrganizationProfileNagDismissed = ref(false)

    const showCompleteOrganizationProfileNag = computed(() =>
        authStore.authenticated
        && authStore.authUser
        && authStore.authUser.isEmailVerified
        && authStore.authUser.isPrivacyAccepted
        && authStore.authUser.isTosAccepted
        && authStore.authUser.isProfessionalAdmin
        && authStore.authUser.organization && !authStore.authUser.organization.isProfileComplete
        && !useMain().completeOrganizationProfileNagDismissed
    )

    /**
     * Check connectivity
     * @returns {Promise<void>}
     */
    const checkConnectivity = async () => {
        if (!navigator.onLine) {
            connectivityStatus.value = 'OFFLINE'
        }

        try {
            if (authStore.authenticated) {
                await apiStore.client().head('api/user')
            } else {
                await apiStore.client().head('')
            }
            // didn't throw an error so all good.
            connectivityStatus.value = 'ok'
        } catch (error) {
            const err = await catchCommonErrors(error)

            if (err.status === 401 && authStore.authenticated) {
                mainStore.resetAllStores()
                router.push({
                    name: 'login', query: {loggedout: btoa('Vous avez été déconnecté')}
                })
            }
            else {
                connectivityStatus.value = err.status
            }
        }
    }

    /**
     * Catch httpr common network errors and update connectivity status
     * @param body
     * @returns {Promise<{errors: {general: string}}|boolean>}
     */
    const catchCommonErrors = async (body) => {
        const error = {status: null, errors: {general: 'Une erreur irrécupérable est survenue. Réessayez.'}}

        if (body instanceof TypeError) {
            connectivityStatus.value = error.status = 'ERR_NETWORK' // update connectivity status
            error.errors = {general: 'Serveur injoignable.'}
        } else if (body instanceof HTTPError) {
            connectivityStatus.value = 'ok'
            error.status = body.response.status
            error.errors = {general: 'Une erreur irrécupérable est survenue. Réessayez.'}

            if (503 === body.response.status) {
                error.errors = {general: 'Le service est temporairement indisponible. Réessayez plus tard.'}
                connectivityStatus.value = body.response.status
            } else if (419 === body.response.status) {
                error.errors = {general: 'Le jeton de sécurité est expiré. Reconnectez vous.'}
            } else if (500 === body.response.status) {
                error.errors = {general: 'Une erreur fatale est survenue. Si cela se reproduit contactez le SPOT.'}
            } else if (429 === body.response.status) {
                error.errors = {general: 'Trop de tentatives. Réessayez plus tard.'}
            } else if (403 === body.response.status) {
                error.errors = {general: 'Opération non permise.'}
            }

        }
        return error
    }

    const intervalCheck = async () => {
        await checkConnectivity()
    }

    const startIntervalCheck = () => {
        if (!interval.value)
            interval.value = setInterval(intervalCheck, (process.env.NODE_ENV === 'production' || isNetworkConnected.value ? 20000 : 5000))
    }

    const stopIntervalCheck = () => {
        if(interval.value) {
            clearInterval(interval.value)
            interval.value = null
        }
    }

    const loadSkills = async (force = false) => {
        const traineeSkills = await useDictionaries().skills(force)
        if(traineeSkills) skills.value = traineeSkills
    }
    const loadAppConfig = async (force = false) => {
        const appSettings = await useDictionaries().app(force)
        if(appSettings) app.value = appSettings
    }

    const preloadAppData = async () => {
        if (isNetworkConnected.value) {
            await loadAppConfig()

            if (authStore.authenticated && isNetworkConnected.value) {
                await loadSkills()
            }
        }
    }

    const resetAllStores = () => {
        const activeStores = Object.keys(getActivePinia().state.value);

        activeStores.forEach((store) => {
            if (undefined !== getActivePinia()._s.get(store).$reset) {
                getActivePinia()._s.get(store).$reset();
            }
        });
    };

    const $reset = () => {
        skills.value = []
    }

    return {
        connectivityStatus,
        routeLoading,
        isNetworkConnected,
        skills,
        app,
        showCompleteOrganizationProfileNag,
        completeOrganizationProfileNagDismissed,
        checkConnectivity,
        catchCommonErrors,
        intervalCheck,
        startIntervalCheck,
        stopIntervalCheck,
        loadSkills,
        loadAppConfig,
        preloadAppData,
        resetAllStores,
        $reset,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useMain, import.meta.hot))
}
