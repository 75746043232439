import {createApp, markRaw} from 'vue'
import {createPinia} from 'pinia'
import PrimeVue from 'primevue/config';
import Tooltip from 'primevue/tooltip';

import DialogService from 'primevue/dialogservice';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';

import App from './App.vue'
import router from './router'

/* Defaults */
import dynamicDialogDefaults from '@/includes/dynamicDialogDefaults'


/* Styles */
import './index.scss'
import '@mdi/font/scss/materialdesignicons.scss'

/* locales */
import i18n from './i18n.js'
import primeLocale from './i18n/prime/fr.js';
import './registerServiceWorker'

const app = createApp(App)

app.provide('dynamicDialogDefaults', dynamicDialogDefaults)

const pinia = createPinia()
app.use(
    pinia.use(({store}) => {
        store.router = markRaw(router)
    }),
)


app.use(router)
app.use(i18n)
app.use(PrimeVue, {
    locale: primeLocale,
})
app.use(DialogService);
app.use(ConfirmationService);
app.use(ToastService)
app.directive('tooltip', Tooltip);
app.mount('#app')
