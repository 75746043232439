import {useStorage} from '@vueuse/core'
import {defineStore, acceptHMRUpdate} from 'pinia'
import {computed, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router"
import {useMain} from '@/stores/main'
import {useApi} from '@/stores/api'
import {useUser} from "@/models/useUser";
import {useTrainee} from "@/models/useTrainee";
import {useDate} from "@/composables/useDate";


export const useAuth = defineStore('auth', () => {

    const router = useRouter()
    const route = useRoute()

    const mainStore = useMain()
    const apiStore = useApi()

    const date = useDate()
    const suspendWatch = ref(false)

    /**
     * States
     */

        // local storage
    const authStatus = useStorage('authStatus', 0)
    const authUserData = useStorage('authUser', {})

    // browser storage
    const authenticatedUser = ref(null)

    const fetchingUser = ref(false)

    const status = computed(() => (authStatus.value === 204 || authStatus.value === 200))

    const hasUserData = computed(() => (authUserData.value && Object.keys(authUserData.value).length > 0))

    const authenticated = computed(() => status.value && hasUserData.value)

    const hasVerified = computed(() => authUser.value
        ? authUser.value.$data.email_verified_at !== null
        : false)

    const isActive = computed(() => authUser.value
        ? authUser.value.$data.activated_at !== null
        : false)


    const isSubscribed = computed(() => authUser.value && !!authUser.value.$data.roles.length)

    const authUser = computed(() => {
        return authenticatedUser.value
    })

    const setAuthStatus = (status, doSuspendWatch = true) => {
        suspendWatch.value = doSuspendWatch
        authStatus.value = status
    }

    const authUserInit = () => {
        if (authenticated.value) {
            // authenticatedUser is set and is same user as information in local storage
            if (authenticatedUser.value && authenticatedUser.value.id === authUserData.value.id) {

                // local storage data seems newer, updates authenticatedUser
                if (authenticatedUser.value.updatedAt.isBefore(date(authUserData.value.updated_at))) {
                    authenticatedUser.value.setData(authUserData.value)
                }
                // authenticatedUser data seems newer, updates local storage
                else if (authenticatedUser.value.updatedAt.isAfter(date(authUserData.value.updated_at))) {
                    authUserData.value = authenticatedUser.value.$data
                }
            }
            // authUser is not set yet, set it
            else if (!authenticatedUser.value) {
                if (authUserData.value.roles.find((role) => role.name === 'trainee')) {
                    authenticatedUser.value = useTrainee({...authUserData.value})
                } else {
                    authenticatedUser.value = useUser({...authUserData.value})
                }
            }

            // authUser is set but is different from local storage
            else if (authenticatedUser.value) {
                authenticatedUser.value = null
            }
        } else {
            authenticatedUser.value = null
        }

        return authenticatedUser.value
    }

    const authUserFetch = async () => {
        if (fetchingUser.value) return null
        fetchingUser.value = true

        let response
        try {
            response = await apiStore.client().get('api/user').json()
        } catch (error) {
            const errors = await mainStore.catchCommonErrors(error)

            if (errors) {
                if (errors.status === 401 && authenticated.value) {
                    mainStore.resetAllStores()
                    router.push({
                        name: 'login', query: {loggedout: btoa('Vous avez été déconnecté')}
                    })
                }
                // if (error.response.status !== 409) throw error
            }

            fetchingUser.value = false
            return false
        }

        if (response) {

            authUserData.value = response.data
            authUserInit()
            // data is fresh we cannot be wrong updating authenticatedUser
            authUser.value.setData(response.data)

            mainStore.connectivityStatus = 'ok'
            fetchingUser.value = false
        }
    }

    const register = async (form, setErrors, processing, isPro = false) => {

        processing.value = true

        try {
            const response = await apiStore.client()
                .post(isPro ? 'register-professional' : 'register-individual', {json: form})

            setAuthStatus(response.status)
            await authUserFetch()

            processing.value = false
            await router.push({name: 'home'})
        } catch (error) {
            await apiStore.processFormErrors(error, setErrors, processing)
            processing.value = false
        }
    }
    const login = async (form, setErrors, processing, redirectTo) => {

        processing.value = true

        let response = null
        try {
            response = await apiStore.client().post('login', {json: form})
        } catch (error) {
            await apiStore.processFormErrors(error, setErrors, processing)
            processing.value = false
            return false
        }

        setAuthStatus(response.status)
        await quickLogin(response.status, redirectTo)

        processing.value = false
    }

    const quickLogin = async (status, redirectTo) => {
        setAuthStatus(status)
        await authUserFetch()

        if (hasUserData.value) {
            if (redirectTo) {
                if (redirectTo.startsWith(useApi().prefixUrl)) {
                    window.location.href = redirectTo
                } else await router.push(redirectTo)
            } else {
                return await router.push({name: 'home'})
            }
        } else {
            return await router.push({name: 'login'})
        }
    }

    const loginRedirect = async (name) => {
        const route = {name: name || 'login'}

        if (useRoute.meta && useRoute.meta.guard === 'auth' && (useRoute.name !== 'login' || useRoute.name !== 'home')) {
            route.query = {
                redirect_to: btoa(useRoute.fullPath)
            }
        }

        await router.push(route)
    }

    const forgotPassword = async (form, setStatus, setErrors, processing) => {

        processing.value = true

        try {
            const response = await apiStore.client().post('forgot-password', {json: form}).json()
            setStatus.value = response.status
        } catch (error) {
            await apiStore.processFormErrors(error, setErrors, processing)
        }

        processing.value = false
    }

    const resetPassword = async (form, setErrors, processing) => {

        processing.value = true

        try {
            const response = await apiStore.client()
                .post('reset-password', {json: form}).json()

            await router.push(
                {
                    name: 'login', query: {reset: btoa(response.status)}
                }
            )
        } catch (error) {
            await apiStore.processFormErrors(error, setErrors, processing)
        }
        processing.value = false
    }

    const changePassword = async (form, setErrors, processing) => {

        processing.value = true

        try {
            const response = await apiStore.client()
                .post('change-password', {json: form}).json()
            return response
        } catch (error) {
            await apiStore.processFormErrors(error, setErrors, processing)
        }
        processing.value = false
    }

    const exportData = async (setErrors, processing) => {

        processing.value = true

        try {
            const response = await apiStore.client()
                .post('api/privacy/export', {}).json()
            processing.value = false
            return response
        } catch (error) {
            await apiStore.processFormErrors(error, setErrors, processing)
        }
        processing.value = false
    }

    const resendEmailVerification = async (setStatus, setErrors, processing) => {
        processing.value = true
        let response = null
        try {
            response = await apiStore.client()
                .post('email/verification-notification')
            setStatus.value = response.status
        } catch (error) {
            setErrors.value = mainStore.catchCommonErrors(error)
        }
        processing.value = false
    }

    const logout = async () => {
        try {
            await apiStore.client().post('logout')

            $reset()
            mainStore.resetAllStores()
            console.log('logged out')
            router.push({
                name: 'login', query: {loggedout: btoa('Vous êtes maintenant déconnecté')}
            })
        } catch (error) {
            let errors = await mainStore.catchCommonErrors(error)

            if (!errors) {
                if (error.response.status === 401) {
                    router.push({
                        name: 'login', query: {loggedout: '1'}
                    })
                    $reset()
                    mainStore.resetAllStores()
                } else if (error.response.status !== 422) throw error
            }
        }
    }


    const $reset = () => {
        setAuthStatus(0)
        authenticatedUser.value = null
        authUserData.value = {}
    }

    // watch
    watch(status, (current, before) => {
        if (current === before) return;

        if (!suspendWatch.value) {
            if (status.value) {
                authUserFetch().then(() => {
                    const redirectTo = route.query.redirect_to && route.query.redirect_to.length > 0 ? atob(route.query.redirect_to) : route.fullPath
                    router.push(redirectTo)
                })
            } else {
                router.push({
                    name: 'login', query: {
                        loggedout: btoa('Vous avez été déconnecté'),
                        redirect_to: btoa(route.fullPath),
                    }
                })
            }
        } else {
            suspendWatch.value = false
        }
    });

    return {
        authStatus,
        status,
        hasUserData,
        authenticated,
        hasVerified,
        isActive,
        isSubscribed,
        authUser,
        setAuthStatus,
        authUserInit,
        authUserFetch,
        register,
        login,
        quickLogin,
        loginRedirect,
        forgotPassword,
        changePassword,
        exportData,
        resetPassword,
        resendEmailVerification,
        logout,
        $reset,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAuth, import.meta.hot))
}
