import {defineStore, acceptHMRUpdate} from 'pinia'

import {useApi} from '@/stores/api'
import {ref} from "vue";

export const useDictionaries = defineStore('dictionaries', () => {
    const apiStore = useApi()

    const $cities = ref()
    const $nafLabels = ref()
    const $drivingLicenses = ref()
    const $skills = ref()
    const $app = ref()

    function $reset() {
        $cities.value = null
        $nafLabels.value = null
        $drivingLicenses.value = null
        $skills.value = null
    }

    const cities = async (force = false) => {
        if ($cities.value && !force) return $cities.value

        try {
            return $cities.value = await apiStore.client().get('api/dictionary/cities', {credentials: 'same-origin',}).json()
        } catch (e) {
            return false
        }
    }

    const citiesDropdown = async () => {
        const $cities = await cities()

        const options = []
        $cities.forEach((value) => {
            options.push({
                label: value.name_lowercase,
                value: value.name_lowercase,
            })
        })

        return options
    }
    const nafLabels = async (force = false) => {
        if ($nafLabels.value && !force) return $nafLabels.value

        try {
            return $nafLabels.value = await apiStore.client().get('api/dictionary/naf_labels', {credentials: 'same-origin',}).json()
        } catch (e) {
            return false
        }
    }

    const nafLabelsDropdown = async () => {
        const $nafLabels = await nafLabels()

        const options = []
        $nafLabels.forEach((value) => {
            options.push({
                label: value.libelle_naf,
                value: value.libelle_naf,
            })
        })

        return options
    }
    const drivingLicenses = async (force = false) => {
        if ($drivingLicenses.value && !force) return $drivingLicenses.value

        try {
            return $drivingLicenses.value = await apiStore.client().get('api/dictionary/driving-licenses', {credentials: 'same-origin',}).json()
        } catch (e) {
            return false
        }
    }

    const drivingLicensesDropdown = async () => {
        const $drivingLicenses = await drivingLicenses()

        const options = []
        $drivingLicenses.forEach((value, index) => {
            options.push({
                label: value,
                value: index,
            })
        })

        return options
    }

    const skills = async (force = false) => {
        if ($skills.value && $skills.value.length && !force) return $skills.value

        try {
            $skills.value = await apiStore.client().get('api/dictionary/skills', {credentials: 'same-origin',}).json()

            return $skills.value
        } catch (e) {
            return false
        }
    }
    const app = async (force = false) => {
        if ($app.value && $app.value.length && !force) return $app.value

        try {
            $app.value = await apiStore.client().get('api/dictionary/app', {credentials: 'include',}).json()

            return $app.value
        } catch (e) {
            return false
        }
    }

    const roles = async () => {
        return {
            'super-admin': 'Super Admin',
            'admin': 'Admin',
            'professional': 'Professionnel',
            'professional-admin': 'Professionnel Administrateur',
            'professional-certifié': 'Professionnel certifié',
        }
    }
    const rolesDropdown = async () => {
        const $roles = await roles()

        const options = []
        $roles.forEach((value, index) => {
            options.push({
                label: value,
                value: index,
            })
        })

        return options
    }

    return {
        cities,
        citiesDropdown,
        nafLabels,
        nafLabelsDropdown,
        drivingLicenses,
        drivingLicensesDropdown,
        skills,
        app,
        roles,
        rolesDropdown,
        $reset,
    }
})


if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useDictionaries, import.meta.hot))
}
